import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { get } from 'lodash';
import 'react-table/react-table.css';
import './styles.scss';

const Table = ({
  clickable,
  columns,
  data,
  defaultPageSize,
  handleClick,
  resizable,
  selectedRow,
  loadingText,
  trClassKey,
  trClassByProp,
  subRowsKey,
  expandedRows,
  SubComponent,
  stickyHeader,
  checkLogTypeDetails,
  isCompact,
  ...rest
}) => {
  const getTrProps = (state, rowInfo) => {
    const rowProps = {
      onClick: (e) => handleClick(rowInfo, e),
      className: `${(selectedRow === get(rowInfo, 'row._original.id')) ? 'selected' : ''}
        ${(trClassKey !== '' && get(rowInfo, `row._original.${trClassKey}`) !== null) ? `${trClassKey}--${get(rowInfo, `row._original.${trClassKey}`)}` : ''}
        ${(trClassByProp !== '' && get(rowInfo, `row._original.${trClassByProp}`) !== null && get(rowInfo, `row._original.${trClassByProp}`) !== '' && get(rowInfo, `row._original.${trClassByProp}`) !== 0) ? `${trClassByProp}--true` : `${trClassByProp}--false`}`,
    };

    if (checkLogTypeDetails) {
      const logType = get(rowInfo, 'row._original.log_type');
      if (logType === 'order_end' && rowInfo.original.details) {
        let details;
        if (typeof rowInfo.original.details === 'string' && rowInfo.original.details.trim().charAt(0) === '{' && rowInfo.original.details.trim().charAt(rowInfo.original.details.trim().length - 1) === '}') {
          details = JSON.parse(rowInfo.original.details);
        }
        if (details && details.booking_type === 'partial') {
          rowProps.className += ' orange';
        }
      }
    }

    return rowProps;
  };

  return (
    <div className={`Table ${clickable ? 'Table-clickable' : ''}`}>
      <div className={`${stickyHeader ? 'table-wrapper' : ''}`}>
        <ReactTable
          className={`${isCompact && 'compact'} -striped`}
          columns={columns}
          data={data}
          defaultPageSize={defaultPageSize}
          subRowsKey={subRowsKey}
          expandedRows={expandedRows}
          SubComponent={SubComponent}
          getTrProps={getTrProps}
          getTdProps={() => ({
            style: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
          resizable={resizable}
          showPageSizeOptions={false}
          loadingText={loadingText || 'Loading...'}
          noDataText=" "
          manual="true"
          {...rest}
        />
      </div>
    </div>
  );
};

Table.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  handleClick: PropTypes.func,
  resizable: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  selectedRow: PropTypes.number,
  loadingText: PropTypes.string,
  trClassKey: PropTypes.string,
  trClassByProp: PropTypes.string,
  subRowsKey: PropTypes.string,
  expandedRows: PropTypes.bool,
  SubComponent: PropTypes.func,
  stickyHeader: PropTypes.bool,
  checkLogTypeDetails: PropTypes.bool,
  isCompact: PropTypes.bool,
};

Table.defaultProps = {
  clickable: false,
  defaultPageSize: 20,
  handleClick: () => { },
  resizable: false,
  trClassKey: '',
  subRowsKey: '',
  expandedRows: false,
  SubComponent: undefined,
  stickyHeader: false,
  isCompact: true,
};

export default Table;
