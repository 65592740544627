/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export const getCompanyDescription = (key) => {
  switch (key) {
    case 'default_language':
      return (
        <span>
          <b>Value type: </b>string<br />
          <b>Description:</b>
          This setting defines the default language for the application.<br />
          <b>Supported Values:</b> en,sl,de,hr
        </span>
      );

    case 'custom_department':
      return (
        <span>
          <b>Value type: </b>string<br />
          <b>Description:</b>
          This setting defines the custom department key for workers tab.<br />
          Custom key must be root name of object.<br />
          If left empty, 'department' accessor will be used.
        </span>
      );

    case 'project_based':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether a company is project-based or not.<br />
          It controls the visibility of the “Technology” and “Departments” columns in Order {'>'} Order Details {'>'} Order Groups <br />
          as well as the visibility of the “Project module” in sidebar.
        </span>
      );

    case 'microplanning_days_to_display':
      return (
        <span>
          <b>Value type: </b>integer<br />
          <b>Description:</b>
          This setting controls the number of days to display on the microplanning calendar.<br />
          The value assigned to this setting determines the range of dates visible in the calendar view.<br />
          If the microplanning_days_to_display setting is defined, it specifies the number of days to display.<br />
          The calendar view will be centered on the current date.<br />
          If this setting is not defined, the default value of 14 days is used.<br />
        </span>
      );

    case 'microplanning_show_day_scroll':
      return (
        <span>
          <b>Value type: </b>bool<br />
          <b>Description:</b>
          This setting determines whether a microplanning shows previous,current and next day buttons.
        </span>
      );

    case 'microplanning_show_week_scroll':
      return (
        <span>
          <b>Value type: </b>bool<br />
          <b>Description:</b>
          This setting determines whether a microplanning shows previous,current and next week buttons.
        </span>
      );

    case 'microplanning_export_analysis':
      return (
        <span>
          <b>Value type: </b>bool<br />
          <b>Description:</b>
          This setting determines whether a microplanning shows “export excel” button.
        </span>
      );

    case 'filter_microplanning_by_location':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether data in microplanning should be filtered by location.<br />
          When enabled, data will be filtered by location, and when disabled, data will not be filtered based on location.
        </span>
      );

    case 'statuses':
      return (
        <span>
          <b>Value type: </b>array(array of strings)<br />
          <b>Description:</b>
          The statuses array is used to define the available order statuses for use in filtering functionality.<br />
          Each string in the array represents a distinct order status.<br />
          <b>Supported values: </b>created,in_progress,in_warehouse,in_transit,finished,delivered,scheduled,paused,partial_delivery,delivered_to_customer
        </span>
      );

    case 'order_groups_hidden_columns':
      return (
        <span>
          <b>Value type: </b>array(array of strings)<br />
          <b>Description:</b>
          The order_groups_hidden_columns key is used to control the visibility of specific columns in a table located in Orders {'>'} Order details {'>'} Order groups tab.<br />
          It allows you to hide or show certain columns based on your configuration.<br />
          order_groups_hidden_columns is an array of string values representing the columns you want to hide.<br />
          When a column name matches a string in this array, that column will be hidden in the table.<br />
          To successfully hide a column, the string in the order_groups_hidden_columns array must match the name of the column in English.
        </span>
      );

    case 'filter_orders_by_location':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The filter_orders_by_location setting controls whether or not orders are filtered by location.<br />
          When enabled, the system will apply location-based filtering to the orders.<br />
          When disabled, orders will not be filtered based on location. When filter_orders_by_location is set to false,<br />
          a {'<Select>'} component is rendered, allowing the user to select a specific location for order filtering.
        </span>
      );

    case 'dashboard_grid_density':
      return (
        <span>
          <b>Value type: </b>integer<br />
          <b>Default: </b> 24<br />
          <b>Description:</b>
          The dashboard_grid_density setting determines the density of the grid used to display widgets on the dashboard.<br />
          It controls the number of columns in the grid layout, which affects the arrangement and sizing of widgets.
        </span>
      );

    case 'filter_orders_by_deadline':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The filter_orders_by_deadline key is used to control the visibility of "Deadline" column and filter in orders table.<br />
          When enabled, the system will show column in Orders and filter option in "Filter by date" dropdown.
        </span>
      );
    case 'filter_orders_by_planned_start':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The filter_orders_by_planned_start key is used to control the visibility of "Planned Start" column and filter in orders table.<br />
          When enabled, the system will show column in Orders and filter option in "Filter by date" dropdown.
        </span>
      );
    case 'filter_orders_by_referent':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The filter_orders_by_referent key is used to control the visibility of "Referent" column and filter in orders table.<br />
          When enabled, the system will show column in Orders and filter dropdown in filter section.
        </span>
      );
    case 'archive_label':
      return (
          <span>
            <b>Value type: </b>string<br />
            <b>Description:</b>
            This setting will overwrite archive status label in orders.<br />
          </span>
      );
    case 'working_injuries':
      return (
            <span>
              <b>Value type: </b>boolean<br />
              <b>Description:</b>
              The working_injuries key is used to control the visibility of "Working injuries" in HR module.<br />
            </span>
      );
    case 'hr_tabs':
      return (
            <span>
            <b>Value type: </b>array(array of objects)<br />
              <b>Description:</b>
              The hr_tabs key controls the visibility of tabs in HR module.
            </span>
      );
    default:
      return 'No description!';
  }
};

export const getLocationDescription = (key) => {
  switch (key) {
    case 'order_groups_custom_columns':
      return (
        <span>
          <b>Value type: </b>array (Array of objects)<br />
          <b>Description:</b>
          The order_groups_custom_columns setting is an array of objects where each object represents a custom column in table located in Order {'>'} Order groups.<br />
          Object must contain accessor from data that is used in table, title_en, title_hr and title_sl where each title represents column name based on selected language in platform.
        </span>
      );

    case 'can_cancel_order':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether to show or hide Order cancellation button located in Order {'>'} order details
        </span>
      );

    case 'orders_check_bookings':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether to check for bookings or not on orders list (send or not to send &check_bookings=true parameter to api).<br />
          If it is set to true on orders list new column “Bookings“ will be shown and new filter checkbox “Not booked only“.
        </span>
      );

    case 'worker_category':
      return (
        <span>
          <b>Value type: </b>array (Array of objects)<br />
          <b>Description:</b>
          The worker_category setting provides a list of worker categories or types that can be associated with employees or workers.<br />
          The worker_category setting is an array of objects where each object represents a worker category.<br />
          The name property within each object contains the name or label of the worker category.<br />
          This setting is used to populate dropdown in User role management {'>'} User details tab {'>'} worker category
        </span>
      );

    case 'pallets_enabled':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The pallets_enabled setting controls the visibility of two tabs, namely Order Pallets and Order Goods Movement within the Order {'>'} Order Details section.<br />
          When enabled, these tabs are shown, and when disabled, they are hidden from view.<br />
          This settings also controls the visibility of Pallets , Warehouse positions and Goods movement tabs in Warehouse module .When enabled,<br />
          these tabs are shown, and when disabled, they are hidden from view.<br />
          This setting in the Warehouse {'>'} Stock {'>'} Product Columns, it controls whether the columns display product_type value when it's true or material code when it's false.
        </span>
      );

    case 'status_change':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The status_change setting control the ability to change the status of an order in Order {'>'}  Order details {'>'}  Order details tab.<br />
          When activated (set to true), it enables the user to modify the order's status using a dropdown selection.<br />
          If deactivated (set to false), the status remains static and unchangeable.
        </span>
      );

    case 'enable_book_tt_and_goods':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          By toggling the enable_book_tt_and_goods setting, you have the flexibility to grant or restrict users<br />
          authority (if it is not Ascalia admin) to initiate bookings for TT and goods.
        </span>
      );

    case 'enable_book_receipt':
      return (
          <span>
            <b>Value type: </b>boolean<br />
            <b>Description:</b>
            By toggling the enable_book_receipt setting you can show/hide book_receipt button in bookings.
          </span>
      );

    case 'join_bookings':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether join booking actions are available to users.<br />
          When enabled, it allows users to join selected bookings or clear their selections.<br />
          When disabled, these join booking actions are hidden, and users cannot perform them.
        </span>
      );

    case 'order_group_create_order':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting controls the visibility of the "Create Order" button within the Order {'>'}  Order Groups section.<br />
          When enabled, the button is displayed, allowing users to create orders. When disabled, the button is hidden.
        </span>
      );

    case 'show_technology_and_department':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting controls the visibility of the columns within the Order {'>'}  Order Groups section.<br />
          If it is set to true two columns will be shown Technology and Department
        </span>
      );

    case 'export_order_endpoint':
      return (
        <span>
          <b>Value type: </b>string<br />
          <b>Default: </b>order_exports<br />
          <b>Description:</b>
          This setting allows customization of the endpoint for exporting order data within the application.<br />
          It determines the specific URL path to be used when exporting orders.
        </span>
      );

    case 'orders_check_bom_availability':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting determines whether to enable or disable the "BOM Availability Check" feature for orders.<br />
          When enabled, the system checks the availability of the BOM for the orders
        </span>
      );

    case 'create_order_button':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          This setting controls the visibility of the "Create Order" button on the user interface in Orders module.<br />
          When set to true, the "Create Order" button will be displayed. When set to false, the button will be hidden.
        </span>
      );

    case 'homepage_links':
      return (
        <span>
          <b>Value type: </b>array of objects<br />
          <b>Description:</b>
          This setting defines a list of links to be displayed on the homepage of the application.<br />
          Each link is represented as an object containing a URL and a title.
        </span>
      );

    case 'dashboard_day_start':
      return (
        <span>
          <b>Value type: </b>String(Format: 'HH:mm:ss' (24-hour time format))<br />
          <b>Description:</b>
          dashboard_day_start setting is used to determine the starting time for the custom time ranges "today" and "yesterday."<br />
          If this setting is defined in the configuration, it influences how these custom time ranges are calculated.
        </span>
      );

    case 'always_allow_booking_confirmation':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          always_allow_booking_confirmation setting is used to change logic on book_tt button in Order {'>'}  Order Details {'>'}  Bookings Tab.<br />
          If it is set to true this will be the logic applyed to that button: (selectedBooking && selectedBooking.booked_confirmation === true)<br />
          else it will be this: (selectedBooking && (selectedBooking.booked_confirmation || selectedBooking.booked_goods === false))
        </span>
      );

    case 'orders_hidden_columns':
      return (
        <span>
          <b>Value type: </b>array(array of strings)<br />
          <b>Description:</b>
          The orders_hidden_columns key is used to control the visibility of specific columns in orders table.<br />
          It allows you to hide or show certain columns based on your configuration.<br />
          orders_hidden_columns is an array of string values representing the columns you want to hide.<br />
          When a column name matches a string in this array, that column will be hidden in the table.<br />
          To successfully hide a column, the string in the orders_hidden_columns array must match the name of the column in English.
        </span>
      );

    case 'show_batches':
      return (
        <span>
          <b>Value type: </b>boolean<br />
          <b>Description:</b>
          The show_batches setting controls the visibility of Batches tab in Order details<br />
          When enabled, this tab is shown, and when disabled, tab is hidden from view.<br />
        </span>
      );

    case 'order_progress_bars':
      return (
          <span>
          <b>Value type: </b>array(array of objects)<br />
            <b>Description:</b>
            The order_progress_bars key controls the visibility of circular porgress bars in order {'>'} order details.
          </span>
      );

    case 'orders_archive':
      return (
          <span>
          <b>Value type: </b>boolean<br />
            <b>Description:</b>
            The orders_archive setting controls the visibility of Archives table column in Order Modul<br />
            When enabled, this column is shown, and when disabled, column is hidden from view.<br />
          </span>
      );

    case 'order_groups_show_waste':
      return (
          <span>
            <b>Value type: </b>bool<br />
            <b>Description:</b>
            The order_groups_show_waste setting controls the visibility of Waste table column in Order groups<br />
            When enabled, this column is shown, and when disabled, column is hidden from view.<br />
          </span>
      );

    case 'push_notifications':
      return (
          <span>
            <b>Value type: </b>bool<br />
            <b>Description:</b>
            The push_notifications setting controls the visibility of push notifications from the server, in the top header and a popup on your screen (Podravka only feature)<br />
            When enabled, you will receive push notifications if you have enabled them, and when disabled, you will not see the notifications if you have disabled them.<br />
            PAGE RELOAD REQUIRED!<br />
          </span>
      );

    case 'notifications_center':
      return (
          <span>
            <b>Value type: </b>bool<br />
            <b>Description:</b>
            The notifications_center setting controls the visibility of notifications from the server, in the top header (KFK feature)<br />
            When enabled, you will receive notifications, and when disabled, you will not see the notifications.<br />
            PAGE RELOAD REQUIRED!<br />
          </span>
      );

    case 'quality_inspection_tabs':
      return (
        <span>
          <b>Value type: </b>array(array of objects)<br />
          <b>Description:</b>
          The quality_inspection_tabs key controls the visibility of tabs in Quality Inspection Modul.
        </span>
      );

    case 'work_process_rating_category_code':
      return (
        <span>
          <b>Value type: </b>string<br />
          <b>Description:</b>
          This setting specifies which code will be used to rate work processes.
        </span>
      );

    case 'layoff_category_code':
      return (
        <span>
          <b>Value type: </b>string<br />
          <b>Description:</b>
          This setting specifies which code will be used to for layoff forms.
        </span>
      );

    case 'number_of_signatories':
      return (
          <span>
            <b>Value type: </b>integer<br />
            <b>Description:</b>
            This setting controls the number of signatories in records.<br />
          </span>
      );

    default:
      return 'No description!';
  }
};

export const companyFormConfigurations = [
  { key: 'default_language', type: 'text', label: 'Default language', defaultValue: '' },
  { key: 'custom_department', type: 'text', label: 'Custom department', defaultValue: '' },
  { key: 'project_based', type: 'checkbox', label: 'Project based', defaultValue: false },
  { key: 'microplanning_days_to_display', type: 'number', label: 'Microplanning days to display', defaultValue: '' },
  { key: 'microplanning_show_day_scroll', type: 'checkbox', label: 'Microplanning show day scroll', defaultValue: false },
  { key: 'microplanning_show_week_scroll', type: 'checkbox', label: 'Microplanning show week scroll', defaultValue: false },
  { key: 'microplanning_export_analysis', type: 'checkbox', label: 'Microplanning export analysis', defaultValue: false },
  { key: 'filter_microplanning_by_location', type: 'checkbox', label: 'Filter microplanning by location', defaultValue: false },
  { key: 'statuses', type: 'JSON', label: 'Statuses', defaultValue: [] },
  { key: 'order_groups_hidden_columns', type: 'JSON', label: 'Order groups hidden columns', defaultValue: [] },
  { key: 'filter_orders_by_location', type: 'checkbox', label: 'Filter orders by location', defaultValue: false },
  { key: 'dashboard_grid_density', type: 'number', label: 'Dashboard grid density', defaultValue: '' },
  { key: 'filter_orders_by_deadline', type: 'checkbox', label: 'Filter orders by deadline', defaultValue: false },
  { key: 'filter_orders_by_planned_start', type: 'checkbox', label: 'Filter orders by planned start', defaultValue: false },
  { key: 'filter_orders_by_referent', type: 'checkbox', label: 'Filter orders by referent', defaultValue: false },
  { key: 'archive_label', type: 'text', label: 'Archive label', defaultValue: '' },
  { key: 'working_injuries', type: 'checkbox', label: 'Show working injuries', defaultValue: false },
  {
    key: 'hr_tabs',
    type: 'multiselect',
    options: [{ name: 'Workers' }, { name: 'Departments' }, { name: 'Shifts' }, { name: 'Employments' }, { name: 'Assigned equipment' },
      { name: 'Forms' }, { name: 'Ratings' }, { name: 'Control panel' }],
    label: 'Human resources Tabs',
    defaultValue: [{ name: 'Workers' }],
  },
];

export const locationFormConfigurations = [
  { key: 'order_groups_custom_columns', type: 'JSON', label: 'Order groups custom columns', defaultValue: [{}] },
  { key: 'can_cancel_order', type: 'checkbox', label: 'Can cancel order', defaultValue: false },
  { key: 'orders_check_bookings', type: 'checkbox', label: 'Orders check bookings', defaultValue: false },
  { key: 'worker_category', type: 'JSON', label: 'Worker category', defaultValue: [{}] },
  { key: 'pallets_enabled', type: 'checkbox', label: 'Pallets enabled', defaultValue: false },
  { key: 'status_change', type: 'checkbox', label: 'Status change', defaultValue: false },
  { key: 'enable_book_tt_and_goods', type: 'checkbox', label: 'Enable book tt and goods', defaultValue: false },
  { key: 'enable_book_receipt', type: 'checkbox', label: 'Enable book receipt', defaultValue: false },
  { key: 'join_bookings', type: 'checkbox', label: 'Join bookings', defaultValue: false },
  { key: 'order_group_create_order', type: 'checkbox', label: 'Order group create order', defaultValue: false },
  { key: 'show_technology_and_department', type: 'checkbox', label: 'Show technology and department', defaultValue: false },
  { key: 'export_order_endpoint', type: 'text', label: 'Export order endpoint', defaultValue: '' },
  { key: 'orders_check_bom_availability', type: 'checkbox', label: 'Orders check bom availability', defaultValue: false },
  { key: 'create_order_button', type: 'checkbox', label: 'Create order button', defaultValue: false },
  { key: 'homepage_links', type: 'JSON', label: 'Homepage links', defaultValue: [{}] },
  { key: 'dashboard_day_start', type: 'text', label: 'Dashboard day start', defaultValue: '' },
  { key: 'always_allow_booking_confirmation', type: 'checkbox', label: 'Always allow booking confirmation', defaultValue: false },
  { key: 'orders_hidden_columns', type: 'JSON', label: 'Orders hidden columns', defaultValue: [] },
  { key: 'show_batches', type: 'checkbox', label: 'Orders batches', defaultValue: false },
  { key: 'orders_archive', type: 'checkbox', label: 'Orders archives', defaultValue: false },
  {
    key: 'order_progress_bars',
    type: 'multiselect',
    options: [{ name: 'items_done/items_total' }, { name: 'parts_done/parts_total' }, { name: 'erp_parts_done/parts_total' }],
    label: 'Orders progress bars',
    defaultValue: [{ name: 'items_done/items_total' }, { name: 'parts_done/parts_total' }],
  },
  { key: 'factory_sap_plant', type: 'text', label: 'Factory SAP plant', defaultValue: '' },
  { key: 'order_groups_show_waste', type: 'checkbox', label: 'Order groups show waste', defaultValue: false },
  { key: 'push_notifications', type: 'checkbox', label: 'Push notifications', defaultValue: false },
  { key: 'notifications_center', type: 'checkbox', label: 'Notification center', defaultValue: false },
  {
    key: 'quality_inspection_tabs',
    type: 'multiselect',
    options: [{ name: 'Gallery' }, { name: 'Complaints' }],
    label: 'Quality Inspection Tabs',
    defaultValue: [{ name: 'Gallery' }],
  },
  { key: 'work_process_rating_category_code', type: 'text', label: 'Work Process Rating Category Code', defaultValue: '' },
  { key: 'layoff_category_code', type: 'text', label: 'Layoff Category Code', defaultValue: '' },
  {
    key: 'number_of_signatories',
    type: 'select',
    options: [{ name: 1 }, { name: 2 }, { name: 3 }, { name: 4 }],
    label: 'Number of signatories',
    defaultValue: [{ name: 3 }],
  },
];
